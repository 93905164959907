import React, { ReactNode, useState } from "react";
import { useAddActMutation, useTypedSelector } from "../store/index.ts";
import { ActAddress, ActeType, AddAct, BirthInfo, DeathInfo, MarriageInfo, PaymentType, TermAgreement } from "../data/interfaces.ts";
import { getBirthActValidationsComponent, getDeathActValidationsComponent, getMarriageActValidationsComponent } from "../data/helpers.tsx";
import Button from "../../src/components/common/Button.tsx"
import CheckBox from "../components/common/CheckBox.tsx";
import { FormikHelpers, useFormik } from "formik";
import { termAgreementSchema } from "../data/validations.tsx";
import PaiementMethod from "../components/actes/PaiementMethod.tsx";
import { useSnackbar } from 'react-simple-snackbar'
interface ActValidationProps {
  setActiveStep: (step: number) => void;
}

const ActValidation: React.FC<ActValidationProps> = ({ setActiveStep }) => {

  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.DEBIT_CARD)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const actType: ActeType | undefined = useTypedSelector((state) => state.auth.actType)
  const { birthAct, marriageAct, deathAct, actAddress} = useTypedSelector(({ birthAct, marriageAct, deathAct, actAddress }) => ({ birthAct, marriageAct, deathAct, actAddress }))
  let validationsComponent: ReactNode = <div></div>

  if (actType === ActeType.BIRTH) {
    validationsComponent = getBirthActValidationsComponent(birthAct, actAddress)
  } else if (actType === ActeType.MARRIAGE) {
    validationsComponent = getMarriageActValidationsComponent(marriageAct, actAddress)
  } else if (actType === ActeType.DEATH) {
    validationsComponent = getDeathActValidationsComponent(deathAct, actAddress)
  }

  
  const [addAct] = useAddActMutation();
  const [openSnackbar] = useSnackbar()

  const successSnackBarContent: ReactNode = (
    <div className="underline decoration-green-600">
      Demande enregistrée avec succès
    </div>
  )

  const failureSnackBarContent: ReactNode = (
    <div className="underline decoration-red-700">
      une erreur s'est produite
    </div>
  )

  const { marriageInfo, birthInfo, deathInfo, actAddressInfo } = useTypedSelector(
    (state) => ({ 
      marriageInfo: state.marriageAct, 
      birthInfo: state.birthAct, 
      deathInfo: state.deathAct, 
      actAddressInfo: state.actAddress, 
      actType: state.auth.actType 
    })
  )

  const handleAddAct = async (agreements: TermAgreement) => {
    if (actType && actAddressInfo) {
      let requestBody: AddAct = { actType, actAddressInfo: actAddressInfo as Required<ActAddress>, agreements, paymentInfo: { type: paymentType } }
      if (actType === ActeType.BIRTH) requestBody.birthInfo = birthInfo as Required<BirthInfo>
      else if (actType === ActeType.DEATH) requestBody.deathInfo = deathInfo as Required<DeathInfo>
      else if (actType === ActeType.MARRIAGE) requestBody.marriageInfo = marriageInfo as Required<MarriageInfo>
      try {
        await addAct(requestBody).unwrap()
        openSnackbar(successSnackBarContent, 2500)
        setActiveStep(0)
      } catch (error) {
        openSnackbar(failureSnackBarContent, 2500)
      }
      
    }
  }

  const handleSubmitForm = async (values: TermAgreement, actions: FormikHelpers<TermAgreement>) => {
    const validValues = values as Required<TermAgreement>
    handleAddAct(validValues)
  }

  const { values, errors, touched, isSubmitting, handleSubmit, setFieldValue } = useFormik<TermAgreement>({
    initialValues: {
      pricing: false,
      generalTerms: false,
      retractRetractionRight: undefined,
      application: false
    },
    validationSchema: termAgreementSchema,
    onSubmit: handleSubmitForm
  })

  const labelClasses = "font-normal text-sm text-gray-800"

  return (  
    <div>
      <div 
        className="flex flex-col border border-orange-500 rounded cursor-pointer hover:-translate-y-2"
        onClick={() => { setActiveStep(0) }}
      >
        { validationsComponent }
        <Button
          className="justify-center p-3 text-customBlue font-semibold border-0 text-lg underline decoration-orange-700"
        >
          MODIFIER
        </Button>
      </div>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center gap-2"
      >
        <div className="h-4" />

        <CheckBox
          id="pricing"
          value={values.pricing}
          labelClassName={labelClasses}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        >
          <div>
            <b> Le montant à régler pour cette demande est de 32 €. Ce prix inclut les frais de traitement et d'expédition. </b> <br/> 
            En cochant cette case, : vous attestez de la véracité des informations saisies et acceptez notre <a href="/politique-de-confidentialite"> <u> politique de confidentialité</u></a> et nos <a href="/cgv"> <u>conditions générales de vente</u></a>.
          </div>
        </CheckBox>

        <CheckBox
          id="retractRetractionRight"
          value={values.retractRetractionRight}
          label="Conformément à l'article L.221-28 1° du Code de la consommation, je demande expressément l'exécution immédiate du service et renonce à mon droit de rétractation de 14 jours."
          labelClassName={labelClasses}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />

      <div className="flex px-1 gap-2 items-center">
        <input
          id="claimRetractionRight"
          type="checkbox"
          checked={values.retractRetractionRight === undefined ? undefined : !values.retractRetractionRight}
          onChange={() => { setFieldValue("retractRetractionRight", false) }}
        />
        <label htmlFor="claimRetractionRight" className={labelClasses}> Je souhaite que la prestation soit exécutée dans 14 jours, conformément à mon droit de rétractation, ce qui repoussera le traitement de ma demande à l'issue de ce délai. </label>
      </div>

        <Button
          className="justify-center py-3 px-8 bg-customBlue rounded text-white text-lg font-semibold underline decoration-orange-700"
          disabled={isSubmitting}
          type="submit"
          onClick={handleSubmit}
        >
          VALIDER LA DEMANDE
        </Button>
      </form>

      { openModal && <PaiementMethod onClose={() => { setOpenModal(false) }} /> }
      
    </div>
  );
}
 
export default ActValidation;