import React from "react";

const LegalNotice = () => {
  return (
    <div className="w-full md:w-4/5 flex flex-col gap-3 p-4 text-customBlue bg-customLightBlue m-2">
      <h2 className="font-bold"> Mentions légales : </h2>
      <h3 className="font-bold"> Éditeur du site : </h3>
      <div>
        Le site internet www. acte-naissance-en-ligne.fr est édité par EOS
        MarKeting EI immatriculée au RCS de Périgueux sous le numéro
        751266438 dont le siège social est sis Le Bourg Est 24300 La
        Chapelle Montmoreau. Mme A. Beunard assure la direction de la
        publication.
      </div>
      <div>
        Mail de contact : XXXXX
      </div>
      <h3 className="font-bold">
        Hébergeur du Site :
      </h3>
      <div>
        Le nom de l’hébergeur : XXXXX
      </div>
      <div>
        la raison sociale : XXXXX
      </div>
      <div>
        L’adresse : XXXXX
      </div>
      <div>
        le numéro de téléphone : XXXXX
      </div>
      <div>
        Le numéro SIRET : XXXXX
      </div>
    </div>
  );
}

export default LegalNotice